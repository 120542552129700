.imageClass {
  height: 100vh;
  /* width: 100vh; */
}


.offcanvas.show:not(.hiding),
.offcanvas.showing {
  width: 250px;
}

.profileImage {
  background-color: #FFC001;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -20px !important;
  margin-top: var(--bs-dropdown-spacer);
}

.navbar {
  background-color: #2490BA;
}

.issues {
  background-color: #FFC001;
}

.pendingissues {
  background-color: #2490BA;
}

.Approvedissues {
  background-color: #84CB61;
}

.Rejectedissues {
  background-color: #D90016;
}

.FontAwesomeIcons {
  color: #FFC001;
}

.Container.ViolationTypePage {
  margin-left: 18% !important;
}

.Container.subdashboard {
  margin-left: 18% !important;
}


.FontAwesomeIcons {
  cursor: pointer;
  margin-right: 5px;

}

button.btn.btn-warning {
  margin-top: 1%;
}

.pointer-cursor {
  cursor: pointer;
}

.custom-modal .modal-content {
  border-radius: 3%;
  /* Adjust the value as needed */
}

.custom-modal-body {
  border: none !important;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.center-cell {
  text-align: center;
  vertical-align: middle;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(3.5 * var(--bs-gutter-x));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .css-4pdmu4-MuiBreadcrumbs-ol {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 16% !important;
  }
}







/* loader css */

/* body {
	background:#ffffff !important;
  color:#FFF;
} */

.overlay {
  position: fixed;
  /* Fixed position to cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.8);
  /* Semi-transparent background */
  display: flex;
  /* Flexbox to center the loader */
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it appears above other content */
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  position: relative;
}

.loader3 span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #ffc001;
  -webkit-animation: loader3 1.5s linear infinite;
  animation: loader3 1.5s linear infinite;
}

.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}